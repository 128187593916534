import useGetPersistParams from "custom-hooks/useGetPersintParamas.hook";
import Link from "next/link";
import React from "react";
import { CPagesPath } from "shared/models/const/pages-path";
import useWindowSize from "custom-hooks/useWindowsSize.hook";

function PropertyCollectionCard(props: any) {
  const ref = useGetPersistParams();
  const size = useWindowSize();
  const width: any = size?.width;

  return (
    <>
      <div className={["collection__card", props.className].join(" ")}>
        {/* <Link
          href={CPagesPath.es.collection_property_detail(props as any) + ref}
          passHref
        > */}
        <a
          href={CPagesPath.es.collection_property_detail(props as any) + ref}
          className="collection__card_link"
          target={width > 991 ? "_blank" : ""}
          rel="nofollow noopener noreferrer"
        >
          <span className="collection__card_label">COLLECTION</span>
          <div className="collection__thumbnail">
            <img
              src={props?.main_picture?.big ?? "/icons/remax-balloon.png"}
              alt={`Collection Image-${props.id}-${props.sector}-${props.realstate_type}`}
              className="collection__thumbnail_image"
            />
          </div>
          <span className="collection__card__description">
            {props.realstate_type}{" "}
            <span className="collection__card__description_light">
              {props.sector}
            </span>
          </span>
        </a>
        {/* </Link> */}
      </div>

      <style>
        {`
        .collection__card {
            width: 100%;
            max-width: 223px;
            position: relative;
            display: block;
            margin: 0 auto;
            padding-top: 25px;
          }
          .collection__card_link,
          .collection__card_link:hover,
          .collection__card_link:active,
          .collection__card_link:visited {
            text-decoration: none;
          }
          .collection__thumbnail {
            width: 100%;
            border-radius: 15px;
            overflow: hidden;
            height: 150px;
            position: relative;
            z-index: 10;
            background-color: #192c4f;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .collection__card_label {
            position: absolute;
            bottom: 88%;
            left: 10px;
            background-color: var(--color-blue-secondary-collector);
            color: #fff;
            padding: 5px 10px;
            border-radius: 10px 10px 0 0;
            letter-spacing: 1px;
            font-size: .9rem;
            font-weight: 400;
          }
          .collection__thumbnail_image {
            width: 100%;'
          }
          .collection__card__description {
            text-align: center;
            display: inline-block;
            width: 100%;
            margin: 20px 0;
            font-size: 1rem;
            font-weight: 500;
          }
          .collection__card__description_light {
            color: #fff;
            font-weight: 400;
          }
          .colletion__footer {
            text-align: center;
          }
          .collection__button {
            border: none;
            outline: none;
            background-color: #e92027;
            color: #fff;
            font-weight: 700;
            font-size: 1rem;
            border-radius: 50px;
            padding: 10px 20px;
            text-align: center;
            cursor: pointer;
          }
          @media screen and (max-width: 400px) {
            .collection__card {
              padding: 0 10px;
              }
          }
                    
        `}
      </style>
    </>
  );
}

export default PropertyCollectionCard;

export interface IPropertyCollectionCard {
  picture: string;
  type: string;
  city: string;
  id: number;
  className?: string;
}
